<template>
  <div>
    <ul class="product__buttons">
      <li
        :class="{'button-disabled': !list.length || $route.path === '/option'}"
        @click="openPopup('list')"
      >
        진열관리
      </li>
      <li
        :class="{'button-disabled': $route.path === '/option'}"
        @click="openPopup('add')"
      >
        + 상품추가
      </li>
    </ul>
    <div v-if="list.length" class="frmBlock__layout">
      <div class="product-list-wrap">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="DAV-block__item"
          :class="{'DAV-block__item--hide': !item.enabled}"
        >
          <div class="DAV-block__bullet">
            <img
              v-if="item.imgUrls"
              class="DAV-block_img"
              :src="item.imgUrls"
            />
            <img v-else src="@/assets/images/common/icon_logo_grey.svg" />
            <span>컴포넌트 숨김</span>
          </div>
          <div class="DAV-block__info">
            <div class="DAV-block__info--no">
              {{ item.name }}
            </div>
            <ul class="DAV-block__info--text">
              <li class="no">{{ item.id }}</li>
              <li class="line"></li>
              <li :class="{sale: item.salesStatus === '판매중'}">
                {{ item.salesStatus }}
              </li>
              <li class="line"></li>
              <li :class="{display: item.isDisplay === '전시'}">
                {{ item.isDisplay }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="allList.length > list.length"
      class="add-button ga_e_17"
      @click="clickMore()"
    >
      더보기 +
    </div>
    <div
      v-if="!list.length"
      class="add-button"
      @click="openPopup('add')"
      :class="{'button-disabled': $route.path === '/option'}"
    >
      + 상품추가
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const ModuleTheme = createNamespacedHelpers("ModuleTheme");
const ModuleAlert = createNamespacedHelpers("ModuleAlert");

export default {
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {
      optionVisible: {},
      allList: [],
      list: [],
      page: 1
    };
  },
  mounted() {
    if (
      this.$route.path !== "/option" &&
      this.$route.query.mode !== "partner" &&
      this.option.value.no.length
    )
      this.productList();
  },
  created() {
    this.$EventBus.$on(`productUpdateValue${this.option.id}`, v => {
      this.allList = v;
      this.countPage();
      this.updateOption();
    });

    if (this.$route.path === "/option") {
      this.updateProperty([], []);
    }
  },
  computed: {
    ...ModuleTheme.mapGetters(["getThemeLoadData"])
  },
  methods: {
    // 상품 목록
    async productList() {
      const params = {
        user_theme_id: this.$route.params.themeId,
        id: this.option.value.no.join(),
        limit: this.option.componentOption.listMaxLength
      };

      this.$emit("isLoading", true);
      await this.$store
        .dispatch("ModuleEditor/actProductList", params)
        .then(response => {
          this.$emit("isLoading", false);
          this.allList = response.data.products;
          this.allList.map(i => {
            const index = this.option.value.no.indexOf(parseInt(i.id));
            i.enabled = this.option.value.enabled[index];
            i.imgUrls = i.imgUrls[0];
            i.salesStatus =
              i.salesStatus === "ON_SALLING"
                ? "판매중"
                : i.salesStatus === "UN_SALLING"
                ? "판매중지"
                : i.salesStatus === "SCHEDULED_SALLING"
                ? "판매대기"
                : i.salesStatus === "SCHEDULED_IN"
                ? "판매진행"
                : i.salesStatus === "SCHEDULED_END"
                ? "판매종료"
                : "품절";
            i.isDisplay = i.isDisplay === "Y" ? "전시" : "미전시";
          });
          this.countPage();
          if (this.option.value.no.length !== this.allList.length) {
            this.updateOption();
          }
        });
    },
    // 팝업 이동
    openPopup(v) {
      if (this.$route.path === "/option") return false;
      if (v === "add") {
        if (this.getThemeLoadData.theme.userSolutionId === null) {
          this.setAlert({
            isVisible: true,
            message: `쇼핑몰 솔루션과 연동 이후 상품을 등록할 수 있습니다.`
          });
          return;
        }
        this.$store.commit("ModuleEditor/setPopup", {
          page: "ComponentProductAdd",
          value: this.allList,
          option: this.option
        });
      } else {
        if (!this.list.length) return;
        this.$store.commit("ModuleEditor/setPopup", {
          page: "ComponentProductList",
          value: this.allList,
          option: this.option
        });
      }
    },
    // 프로퍼티 업데이트 (파트너)
    updateProperty(no, enabled) {
      let modified = {
        id: this.option.componentOption.id,
        properties: [
          {
            id: this.option.componentOption.properties[0].id,
            initialValue: no
          },
          {
            id: this.option.componentOption.properties[1].id,
            initialValue: enabled
          }
        ]
      };
      this.$emit("updateProperty", this.option.componentOption.key, modified);
    },
    // 옵션 Update
    updateOption() {
      const no = [];
      const enabled = [];
      this.allList.map(i => {
        no.push(parseInt(i.id));
        enabled.push(i.enabled);
      });

      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[0].key,
        no
      );
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[1].key,
        enabled
      );

      if (this.$route.path === "/option") {
        this.updateProperty(no, enabled);
      }
    },
    // 페이징 계산
    countPage() {
      this.page = 1;
      const allList = [...this.allList];
      this.list = allList.splice(0, 5);
    },
    // 더보기 버튼
    clickMore() {
      const allList = [...this.allList];
      const data = allList.splice((this.page - 1) * 10 + 5, 10);
      this.list = this.list.concat(data);
      this.page = this.page + 1;
    },
    ...ModuleAlert.mapMutations(["setAlert"])
  }
};
</script>

<style lang="scss" scoped>
.product__buttons {
  float: right;
  margin-top: -54px;
  position: relative;
  z-index: 1;

  &--margin1 {
    margin-right: 60px;
  }

  &--margin2 {
    margin-right: 90px;
  }

  li {
    display: inline-block;
    height: 26px;
    line-height: 26px;
    text-align: center;
    padding: 0 8px;
    font-size: 14px;
    color: #797979;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.button-disabled {
  opacity: 0.5;
}

.frmBlock__layout {
  margin-bottom: 24px;
}

.product-list-wrap {
  width: 100%;

  .DAV-block {
    &__name {
      flex-grow: 1;
      text-align: left;
      @include text-ellipsis;
    }
  }
  .DAV-block__item {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    height: 81px;
    margin-top: 8px;
    border: 1px solid #dcdcdc;
    box-sizing: border-box;
    @include box;

    &:first-child {
      margin-top: 0px;
    }

    &--active {
      z-index: 1 !important;
      border: 0 !important;
      box-shadow: 0px 0px 8px #54c7a2 !important;

      & .DAV-block__supports--move {
        background-image: url($path + "ico_move_active.svg");
      }
    }
    &--hide {
      .DAV-block__bullet span {
        display: block;
      }
      .DAV-block__options--display span {
        background-image: url($path + "ico_opt_display_show.svg");
      }
    }
  }
  .DAV-block__bullet {
    flex-shrink: 0;
    position: relative;
    width: 64px;
    height: 64px;
    margin: 0 16px;
    background-color: #f0f0f0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background-color: rgba(63, 63, 63, 0.4);
      background-image: url($path + "ico_opt_display_hide.svg");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 16px auto;
      text-indent: -9999px;
    }

    .DAV-block_img {
      width: 64px;
      height: 64px;
      border-radius: 12px;
    }
  }
  .DAV-block__info {
    flex-grow: 1;
    overflow: hidden;
    padding-right: 16px;

    &--no {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &--text {
      li {
        display: inline-block;
        color: #b7b7b7;
      }

      .line {
        width: 1px;
        height: 12px;
        background: #dcdcdc;
        margin: 0 7px;
      }

      .no {
        color: #8d8d8d;
      }

      .sale {
        color: #3366ff;
      }

      .display {
        color: #ed5076;
      }
    }
  }
}

.add-button {
  height: 56px;
  border: 2px dashed #d8dae5;
  border-radius: 8px;
  color: #797979;
  text-align: center;
  line-height: 52px;
  cursor: pointer;
  box-sizing: border-box;
}
</style>

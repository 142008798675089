var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ul', {
    staticClass: "product__buttons"
  }, [_c('li', {
    class: {
      'button-disabled': !_vm.list.length || _vm.$route.path === '/option'
    },
    on: {
      "click": function click($event) {
        return _vm.openPopup('list');
      }
    }
  }, [_vm._v(" 진열관리 ")]), _c('li', {
    class: {
      'button-disabled': _vm.$route.path === '/option'
    },
    on: {
      "click": function click($event) {
        return _vm.openPopup('add');
      }
    }
  }, [_vm._v(" + 상품추가 ")])]), _vm.list.length ? _c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('div', {
    staticClass: "product-list-wrap"
  }, _vm._l(_vm.list, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "DAV-block__item",
      class: {
        'DAV-block__item--hide': !item.enabled
      }
    }, [_c('div', {
      staticClass: "DAV-block__bullet"
    }, [item.imgUrls ? _c('img', {
      staticClass: "DAV-block_img",
      attrs: {
        "src": item.imgUrls
      }
    }) : _c('img', {
      attrs: {
        "src": require("@/assets/images/common/icon_logo_grey.svg")
      }
    }), _c('span', [_vm._v("컴포넌트 숨김")])]), _c('div', {
      staticClass: "DAV-block__info"
    }, [_c('div', {
      staticClass: "DAV-block__info--no"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('ul', {
      staticClass: "DAV-block__info--text"
    }, [_c('li', {
      staticClass: "no"
    }, [_vm._v(_vm._s(item.id))]), _c('li', {
      staticClass: "line"
    }), _c('li', {
      class: {
        sale: item.salesStatus === '판매중'
      }
    }, [_vm._v(" " + _vm._s(item.salesStatus) + " ")]), _c('li', {
      staticClass: "line"
    }), _c('li', {
      class: {
        display: item.isDisplay === '전시'
      }
    }, [_vm._v(" " + _vm._s(item.isDisplay) + " ")])])])]);
  }), 0)]) : _vm._e(), _vm.allList.length > _vm.list.length ? _c('div', {
    staticClass: "add-button ga_e_17",
    on: {
      "click": function click($event) {
        return _vm.clickMore();
      }
    }
  }, [_vm._v(" 더보기 + ")]) : _vm._e(), !_vm.list.length ? _c('div', {
    staticClass: "add-button",
    class: {
      'button-disabled': _vm.$route.path === '/option'
    },
    on: {
      "click": function click($event) {
        return _vm.openPopup('add');
      }
    }
  }, [_vm._v(" + 상품추가 ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }